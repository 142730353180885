import { FormEvent, useCallback, useEffect, useState } from 'react';

import { captureMessage } from '@sentry/nextjs';
import { signIn } from 'next-auth/react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { Button, Input, Label, Text } from '@olist/united';

import { GO_TO_SHOP_QUERY } from 'modules/common/constants';
import { Container } from 'modules/components/Container';
import { Form } from 'modules/components/Form';
import { ROUTES } from 'modules/routes';
import { gtag } from 'utils/gtag';

import VndaLogo from '../../../../public/vnda.svg';
import { Footer } from '../Footer';
import { Link } from '../Link';
import { PasswordVisibilityAdornment } from '../PasswordVisibilityAdornment.tsx';
import { ErrorMessage } from './ErrorMessage';
import { FormContainer } from './FormContainer';
import { Information } from './Information';
import { NoAccessHelperText } from './NoAccessHelperText';

import type { CaptureContext } from '@sentry/types';
import type { NextPage } from 'next';

export const Login: NextPage = () => {
  const router = useRouter();

  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    gtag('set', {
      page_title: 'Entrar',
    });
  });

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      setError(false);
      setSubmitted(true);

      // @ts-ignore
      const { email, password } = event.currentTarget.elements;

      const result = await signIn('credentials', {
        redirect: false,
        email: email.value,
        password: password.value,
      });

      const shopCode = router.query[GO_TO_SHOP_QUERY] as string;
      const sentryMessageContext: CaptureContext = {
        user: {
          email: email.value,
        },
      };

      if (shopCode) {
        sentryMessageContext.tags = {
          requested_shop_code: shopCode,
        };
      }

      if (result?.ok) {
        gtag('event', 'login_successful', {
          app_name: 'Vnda Login',
          screen_name: 'Login',
        });

        captureMessage('login_successful', sentryMessageContext);

        if (router.query[GO_TO_SHOP_QUERY]) {
          router.push(ROUTES.GO_TO_SHOP_PREFIX + shopCode);
          return;
        }

        router.push(ROUTES.SHOPS_LIST);
        return;
      }

      gtag('event', 'login_failure', {
        app_name: 'Vnda Login',
        screen_name: 'Login',
      });

      captureMessage('login_failure', sentryMessageContext);

      setError(true);
      setSubmitted(false);
    },
    [router]
  );

  return (
    <Container>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          padding: '0px 16px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '56px 0px 32px',
          }}
        >
          <Image alt="Vnda" src={VndaLogo} width={120} height={70} />
        </div>
        <FormContainer>
          <Text.H3 textAlign="center" fontSize={26}>
            Acesse o admin Vnda
          </Text.H3>
          <Form onSubmit={onSubmit}>
            <div style={{ margin: '16px 0' }}>
              <Label>E-mail:</Label>
              <Input required error={error} type="email" name="email" disabled={submitted} />
            </div>
            <div style={{ margin: '16px 0' }}>
              <Label>Senha:</Label>
              <Input
                type={visible ? 'text' : 'password'}
                name="password"
                disabled={submitted}
                error={error}
                append={<PasswordVisibilityAdornment visible={visible} onChange={setVisible} />}
              />
            </div>
            <Link href={{ pathname: ROUTES.PASSWORD_RESET }}>Esqueceu sua senha?</Link>
            <div style={{ marginTop: 40 }}>
              {error && <ErrorMessage />}
              <Button
                required
                fullWidth
                disabled={submitted}
                loading={submitted}
                variation="alternate"
              >
                entrar
              </Button>
            </div>
          </Form>
        </FormContainer>
        {error && <NoAccessHelperText />}
        <Information />
      </div>
      <Footer />
    </Container>
  );
};
