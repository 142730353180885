import styled from 'styled-components';

import { Text } from '@olist/united';

import { VNDA_SUPPORT_EMAIL } from 'modules/common/constants';
import { Link } from 'modules/components/Link';

const Wrapper = styled.div`
  background: #fff2e8;
  border: 1px solid rgb(209, 209, 227);
  border-radius: 0 0 16px 16px;
  box-sizing: border-box;
  margin-top: -10px;
  max-width: 410px;
  padding: 24px;
  width: 100%;
  z-index: 9;
`;

export const NoAccessHelperText = () => {
  return (
    <Wrapper>
      <Text>
        Se não conseguir acessar sua loja, clique para resetar a sua senha. Se o problema persistir,
        entre em contato pelo{' '}
        <Link href={`mailto:${VNDA_SUPPORT_EMAIL}`} target="_blank" rel="noreferrer" anchor>
          {VNDA_SUPPORT_EMAIL}
        </Link>
        .
      </Text>
    </Wrapper>
  );
};
